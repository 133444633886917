@import url(https://fonts.googleapis.com/css?family=Open+Sans|Slabo+27px);
.mydoc__widget {
  min-height: 160px;
  overflow: hidden;
  position: relative;
  background: white;
  border-radius: 5px;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 1;
}
.mydoc__widget *, .mydoc__widget *:before, .mydoc__widget *:after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
}
.mydoc__widget *:focus {
    outline: 0;
}
.mydoc__widget a {
    color: #44b1a8;
}
.fade-enter-active, .fade-leave-active {
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.mydoc__doctor[data-v-1aeb56bc] {
  background: #fff;
  padding: 15px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.mydoc__doctor__specialties[data-v-1aeb56bc] {
  margin-top: 7px;
  font-size: 0;
}
.mydoc__doctor__specialties .mydoc__doctor__specialty a[data-v-1aeb56bc] {
    padding: 3px 5px 4px 5px;
    border-radius: 3px;
    display: inline-block;
    font-size: 9px;
    background: rgba(0, 0, 0, 0.25);
    color: white;
    text-decoration: none;
}
.mydoc__doctor__specialties .mydoc__doctor__specialty a[data-v-1aeb56bc]:hover {
      background: rgba(0, 0, 0, 0.3);
}
.mydoc__doctor__specialties .mydoc__doctor__specialty a[data-v-1aeb56bc]:active {
      background: rgba(0, 0, 0, 0.35);
}
.mydoc__doctor__specialties .mydoc__doctor__specialty a + span[data-v-1aeb56bc] {
      margin-left: 5px;
}
.mydoc__doctor__name a[data-v-1aeb56bc] {
  font-size: 17px;
  font-weight: 600;
  color: black;
  text-decoration: none;
  font-family: 'Slabo 27px', sans-serif;
}
.mydoc__doctor__photo[data-v-1aeb56bc] {
  margin-right: 15px;
  font-size: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid rgba(0, 0, 0, 0.25);
  display: block;
  background: rgba(0, 0, 0, 0.1);
}
.mydoc__doctor__photo .mydoc__doctor__image[data-v-1aeb56bc] {
    width: 100%;
    height: auto;
}
.mydoc__clinic__address[data-v-1aeb56bc] {
  font-size: 14px;
  font-weight: 400;
  color: black;
  text-decoration: none;
  font-family: 'Slabo 27px', sans-serif;
}

.mydoc__calendar[data-v-64d4b073] {
  text-align: center;
}
.mydoc__calendar.mydoc__calendar--expanded .mydoc__calendar__slide .mydoc__day__item[data-v-64d4b073]:nth-child(n+4) {
    display: block;
}
.mydoc__calendar.mydoc__calendar--expanded .mydoc__calendar__expander button .mydoc--icon[data-v-64d4b073] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.mydoc__calendar__header[data-v-64d4b073] {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  padding: 10px 35px;
  font-size: 16px;
  color: black;
  font-family: 'Slabo 27px', serif;
  border-bottom: 1px solid #e6e3e3;
  background: #fbf8f8;
}
.mydoc__calendar__header .mydoc__calendar__arrow[data-v-64d4b073] {
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    border: 0;
    background: transparent;
    padding: 0;
    width: 12px;
    height: 12px;
    top: 50%;
    margin-top: -7px;
    position: absolute;
    cursor: pointer;
}
.mydoc__calendar__header .mydoc__calendar__arrow.slick-disabled[data-v-64d4b073] {
      opacity: 0;
}
.mydoc__calendar__header .mydoc__calendar__arrow:hover svg[data-v-64d4b073] {
      fill: #7e7e7e;
}
.mydoc__calendar__header .mydoc__calendar__arrow:active svg[data-v-64d4b073] {
      fill: black;
}
.mydoc__calendar__header .mydoc__calendar__arrow[data-v-64d4b073]:focus {
      outline: 0;
}
.mydoc__calendar__header .mydoc__calendar__arrow svg[data-v-64d4b073] {
      display: block;
      width: 12px;
      height: 12px;
      fill: #a4a4a4;
}
.mydoc__calendar__header .mydoc__calendar__arrow.mydoc__calendar__arrow--prev[data-v-64d4b073] {
      left: 10px;
}
.mydoc__calendar__header .mydoc__calendar__arrow.mydoc__calendar__arrow--next[data-v-64d4b073] {
      right: 10px;
}
.mydoc__calendar__footer[data-v-64d4b073] {
  margin-top: 15px;
}
.mydoc__calendar__slider[data-v-64d4b073] {
  margin-left: -1px;
}
.mydoc__calendar__slider.mydoc__calendar__slider--notime[data-v-64d4b073] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 80px;
    font-size: 16px;
    font-weight: 600;
}
.mydoc__calendar__slider.mydoc__calendar__slider--notime .mydoc__calendar__phone[data-v-64d4b073] {
      margin-top: 7px;
      font-size: 18px;
}
.mydoc__calendar__slider.mydoc__calendar__slider--notime .mydoc__calendar__phone a[data-v-64d4b073] {
        text-decoration: none;
}
.mydoc__calendar__slide .mydoc__day__header[data-v-64d4b073] {
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px dashed #e6e3e3;
  border-left: 1px dashed #e6e3e3;
  background: #fbf8f8;
  height: 31px;
  line-height: 31px;
}
.mydoc__calendar__slide .mydoc__day__item[data-v-64d4b073] {
  border-left: 1px dashed #e6e3e3;
  font-size: 0;
  background: white;
  height: 31px;
  line-height: 32px;
}
.mydoc__calendar__slide .mydoc__day__item[data-v-64d4b073]:nth-child(n+4) {
    display: none;
}
.mydoc__calendar__slide .mydoc__day__item + .mydoc__day__item[data-v-64d4b073] {
    border-top: 1px dashed #e6e3e3;
}
.mydoc__calendar__slide .mydoc__day__item label[data-v-64d4b073] {
    height: 100%;
    cursor: pointer;
    padding: 0 10px;
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: 1;
}
.mydoc__calendar__slide .mydoc__day__item label[data-v-64d4b073]:hover {
      position: relative;
      z-index: 1;
      background: #fbf8f8;
}
.mydoc__calendar__slide .mydoc__day__item label .mydoc__day__content[data-v-64d4b073] {
      font-size: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      line-height: 16px;
}
.mydoc__calendar__slide .mydoc__day__item label .mydoc__day__content .mydoc__day__state[data-v-64d4b073] {
        -webkit-transition: all 400ms ease-in-out;
        transition: all 400ms ease-in-out;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        font-size: 0;
        background: #e0e0e0;
        border-radius: 50%;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0);
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0);
}
.mydoc__calendar__slide .mydoc__day__item label .mydoc__day__content .mydoc__day__state .mydoc__day__bullet[data-v-64d4b073] {
          -webkit-transition: all 400ms ease-in-out;
          transition: all 400ms ease-in-out;
          position: absolute;
          opacity: 0;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #e6e3e3;
}
.mydoc__calendar__slide .mydoc__day__item label .mydoc__day__content .mydoc__day__state svg[data-v-64d4b073] {
          -webkit-transition: all 200ms ease-in-out;
          transition: all 200ms ease-in-out;
          opacity: 0;
          width: 10px;
          height: auto;
}
.mydoc__calendar__slide .mydoc__day__item label .mydoc__day__content .mydoc__day__value[data-v-64d4b073] {
        font-size: 12px;
        line-height: 17px;
        position: relative;
}
.mydoc__calendar__slide .mydoc__day__item label .mydoc__day__content .mydoc__day__value.mydoc__day__remote[data-v-64d4b073]:after {
          content: '';
          position: absolute;
          right: -17px;
          top: 50%;
          margin-top: -8.5px;
          width: 15px;
          height: 15px;
          background: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12px" height="12px" viewBox="0 0 28 28" xml:space="preserve"><g><path fill="%2300a398" d="M28 5.5v17c0 0.406-0.25 0.766-0.609 0.922-0.125 0.047-0.266 0.078-0.391 0.078-0.266 0-0.516-0.094-0.703-0.297l-6.297-6.297v2.594c0 2.484-2.016 4.5-4.5 4.5h-11c-2.484 0-4.5-2.016-4.5-4.5v-11c0-2.484 2.016-4.5 4.5-4.5h11c2.484 0 4.5 2.016 4.5 4.5v2.578l6.297-6.281c0.187-0.203 0.438-0.297 0.703-0.297 0.125 0 0.266 0.031 0.391 0.078 0.359 0.156 0.609 0.516 0.609 0.922z"></path></g></svg>') 50% 50% no-repeat;
          display: inline-block;
          color: #00a398;
}
.mydoc__calendar__slide .mydoc__day__item label input[type=radio][data-v-64d4b073] {
      display: none;
}
.mydoc__calendar__slide .mydoc__day__item label input[type=radio]:checked + .mydoc__day__content .mydoc__day__state[data-v-64d4b073] {
      background: white;
      -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
              box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}
.mydoc__calendar__slide .mydoc__day__item label input[type=radio]:checked + .mydoc__day__content .mydoc__day__state .mydoc__day__bullet[data-v-64d4b073], .mydoc__calendar__slide .mydoc__day__item label input[type=radio]:checked + .mydoc__day__content .mydoc__day__state svg[data-v-64d4b073] {
        opacity: 1;
}
.mydoc__calendar__box[data-v-64d4b073] {
  overflow: hidden;
  border: 1px solid #e6e3e3;
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.mydoc__calendar__register[data-v-64d4b073],
.mydoc__calendar__expander[data-v-64d4b073] {
  margin: 0 -1px;
}
.mydoc__calendar__register button[data-v-64d4b073],
  .mydoc__calendar__expander button[data-v-64d4b073] {
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    line-height: 40px;
    cursor: pointer;
    background: #44b1a8;
    border: 0;
    height: 40px;
    font-family: 'Slabo 27px', sans-serif;
    font-size: 15px;
    display: block;
    width: 100%;
    color: white;
}
.mydoc__calendar__register button[data-v-64d4b073]:hover,
    .mydoc__calendar__expander button[data-v-64d4b073]:hover {
      background: #52bdb4;
}
.mydoc__calendar__register button[data-v-64d4b073]:active,
    .mydoc__calendar__expander button[data-v-64d4b073]:active {
      background: #64c4bc;
}
.mydoc__calendar__register button[data-v-64d4b073]:focus,
    .mydoc__calendar__expander button[data-v-64d4b073]:focus {
      outline: 0;
}
.mydoc__calendar__register button[disabled][data-v-64d4b073],
    .mydoc__calendar__expander button[disabled][data-v-64d4b073] {
      pointer-events: none;
      cursor: default;
      opacity: 0.65;
}
.mydoc__calendar__register button .mydoc--icon[data-v-64d4b073],
    .mydoc__calendar__expander button .mydoc--icon[data-v-64d4b073] {
      margin-top: -1.5px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 3px;
      width: 12px;
      height: 10px;
}
.mydoc__calendar__register button .mydoc--icon svg[data-v-64d4b073],
      .mydoc__calendar__expander button .mydoc--icon svg[data-v-64d4b073] {
        display: block;
        width: 12px;
        height: 10px;
        fill: rgba(0, 0, 0, 0.2);
}
.mydoc__calendar__expander[data-v-64d4b073] {
  border-top: 1px solid #e6e3e3;
  margin: 0;
  background: #fbf8f8;
}
.mydoc__calendar__expander button[data-v-64d4b073] {
    background: transparent;
    color: #44b1a8;
}
.mydoc__calendar__expander button[data-v-64d4b073]:hover {
      background: #f7f2f2;
}
.mydoc__calendar__expander button[data-v-64d4b073]:active {
      background: transparent;
}
.mydoc__calendar__register[data-v-64d4b073] {
  margin-top: 20px;
  height: auto;
}
.mydoc__calendar__register button[data-v-64d4b073] {
    border-radius: 4px;
}
.mydoc__calendar__register button:hover .mydoc--icon[data-v-64d4b073] {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
}
.mydoc__calendar__register button .mydoc--icon svg[data-v-64d4b073] {
      fill: white;
}
/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}
.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

.mydoc__loader[data-v-2b04216b] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: white;
}
.mydoc__loader__spinner[data-v-2b04216b] {
  width: 36px;
  height: 36px;
}
